import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'

import pic01 from '../assets/images/pic01.jpg'
import pic02 from '../assets/images/pic02.jpg'
import pic03 from '../assets/images/pic03.jpg'
import pic04 from '../assets/images/pic04.jpg'
import pic05 from '../assets/images/pic05.jpg'
import pic06 from '../assets/images/pic06.jpg'

const HomeIndex = () => {
  return (
    <Layout>
      <Helmet
        title="FaithStream"
        meta={[
          { name: 'description', content: 'Faith' },
          { name: 'keywords', content: 'streaming, OnDemand' },
        ]}
      ></Helmet>

      <Banner />

      <div id="main">
        <section id="one" className="tiles">
          <article style={{ backgroundImage: `url(${pic01})` }}>
            <header className="major">
              <h3>Live Services</h3>
              <p>Watch Live Services from organizations around the United States</p>
            </header>
            {/* <Link to="/landing" className="link primary"></Link> */}
          </article>
          <article style={{ backgroundImage: `url(${pic02})` }}>
            <header className="major">
              <h3>Replays</h3>
              <p>Watch the previous 2 Live Streams of an organization with ease by simply clicking on a replay date</p>
            </header>
            {/* <Link to="/landing" className="link primary"></Link> */}
          </article>
          <article style={{ backgroundImage: `url(${pic03})` }}>
            <header className="major">
              <h3>Upcoming Schedule</h3>
              <p>See the next presentation times per organization</p>
            </header>
            {/* <Link to="/landing" className="link primary"></Link> */}
          </article>
          <article style={{ backgroundImage: `url(${pic04})` }}>
            <header className="major">
              <h3>On Demand</h3>
              <p>Watch family friendly series with no ads or commercials.</p><br></br><p>Feature is coming soon...</p>
            </header>
            {/* <Link to="/landing" className="link primary"></Link> */}
          </article>
          <article style={{ backgroundImage: `url(${pic05})` }}>
            <header className="major">
              <h3>TV Streaming Apps</h3>
              <p>Coming soon is the release of the Roku and AppleTV apps with FireTV shortly afterwards</p>
            </header>
            {/* <Link to="/landing" className="link primary"></Link> */}
          </article>
          <article style={{ backgroundImage: `url(${pic06})` }}>
            <header className="major">
              <h3>Plus Much More</h3>
              <p>Additional Features to come such as donation capability, ios and google apps, special nationwide presentations, and more</p>
            </header>
            {/* <Link to="/landing" className="link primary"></Link> */}
          </article>
        </section>
        <section id="two">
          <div className="inner">
            <header className="major">
              <h2>Imporant Covid-19 Update</h2>
            </header>
            <p>
              During these hard times FaithStream is announcing the early release in order to help organizations spread their message to the masses.  All services are being offered Free of charge until further notice.
            </p>
            <ul className="actions">
              <li>
              <a href="https://forms.gle/zWB2qiFqRps6ag8v5" target="_blank" className="button next scrolly">Organization Sign Up</a>
              </li>
            </ul>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default HomeIndex
