import React from 'react'

const Banner = (props) => (
    <section id="banner" className="major">
        <div className="inner">
            <header className="major">
                <h1>FaithStream</h1>
            </header>
            <div className="content">
                <p>Live Worship and On Demand Content provided by Faith Organizations</p>
                <ul className="actions">
                    <li><a href="https://watch.faith-stream.com" target="_blank" className="button next scrolly">Watch Now</a></li>
                </ul>
            </div>
        </div>
    </section>
)

export default Banner
